import { template as template_28c1189410c94d9891a4624a390eb212 } from "@ember/template-compiler";
const FKLabel = template_28c1189410c94d9891a4624a390eb212(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
