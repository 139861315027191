import { template as template_7bb2ce41445d4bbe93c8d975a0a0617c } from "@ember/template-compiler";
const FKControlMenuContainer = template_7bb2ce41445d4bbe93c8d975a0a0617c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
