import { template as template_69d49bdf7b8344608259cb8ba1e08890 } from "@ember/template-compiler";
const WelcomeHeader = template_69d49bdf7b8344608259cb8ba1e08890(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
