import { template as template_39f72f11c0d94b5dba729d6e236db5ed } from "@ember/template-compiler";
const SidebarSectionMessage = template_39f72f11c0d94b5dba729d6e236db5ed(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
